import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Collapse, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"
import { Link, withRouter } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import { connect } from "react-redux"

const Navbar = props => {
  const [cadastros, setCadastros] = useState(false)

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })
  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <nav
          className="navbar navbar-light navbar-expand-lg topnav-menu"
          id="navigation"
        >
          <Collapse
            isOpen={props.leftMenu}
            className="navbar-collapse"
          >
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle arrow-none"
                  to="#"
                  onClick={e => {
                    e.preventDefault()
                    setCadastros(!cadastros)
                  }}
                >
                  {props.t("Cadastros")} <div className="arrow-down"></div>
                </Link>

                <div className={classname("dropdown-menu mega-dropdown-menu dropdown-menu-right")}>

                  <Row>
                    <Col lg={6}>
                      <div>
                        <Link to="lista-usuarios" className="dropdown-item">
                          {props.t("Usuários")}
                        </Link>
                        <Link to="lista-exportadores" className="dropdown-item">
                          {props.t("Exportadores")}
                        </Link>
                        <Link to="lista-fornecedores" className="dropdown-item">
                          {props.t("Fornecedores")}
                        </Link>
                        <Link to="lista-clientes" className="dropdown-item">
                          {props.t("Clientes")}
                        </Link>
                        <Link to="lista-produtos" className="dropdown-item">
                          {props.t("Produtos")}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </li>
            </ul>
          </Collapse>
          <Link to="lista-orcamentos" className="nav-item-orcamento">
            {props.t("Orçamentos")}
          </Link>
        </nav>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout
  return { leftMenu }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
)