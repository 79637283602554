import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { Row, Col, Alert, Container } from "reactstrap"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField, AvFeedback, AvGroup, AvInput } from "availity-reactstrap-validation"

// actions
import { loginUser, apiError } from "../../store/actions"

// import images
import logo from "../../assets/images/logo.svg"
import { globalLoading } from 'react-global-loading';

const Login = (props) => {
  useEffect(() => {
    document.body.className = "authentication-bg";
    return function cleanup() {
      document.body.className = "";
    };
  });

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    globalLoading.show();
    props.loginUser(values, props.history)
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
      <ToastContainer />
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Bem Vindo!</h5>
                    <p className="text-white-50 mb-0">Efetue o Login para continuar no Sistema XD Consulting.</p>
                    <Link to="/" className="logo logo-admin mt-4">
                      <img src={logo} alt=""  />
                    </Link>
                  </div>
                </div>
                <div className="card-body pt-5">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >

                      <div className="mb-3">
                        <AvGroup>
                          <AvInput
                            name="email"
                            label="Email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            required
                          />
                          <AvFeedback>Informe o Email</AvFeedback>
                        </AvGroup>
                      </div>

                      <div className="mb-3">
                      <AvGroup>
                        <AvInput
                          name="senha"
                          label="Senha"
                          type="password"
                          required
                          placeholder="Insira a senha"
                        />
                        <AvFeedback>Informe a Senha</AvFeedback>
                        </AvGroup>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </AvForm>

                  </div>
                </div>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error } = state.Login
  return { error }
}

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
}