import React from "react"
import { Redirect } from "react-router-dom"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"


// Cadastros
import ListaClientes from "../pages/cadastros/clientes/lista-clientes"
import ListaFornecedores from "../pages/cadastros/fornecedores/lista-fornecedores"
import ListaProdutos from "../pages/cadastros/produtos/lista-produtos"
import ListaOrcamentos from "../pages/cadastros/orcamentos/lista-orcamentos"
import ListaExportadores from "../pages/cadastros/exportadores/lista-exportadores"
import ListaUsuarios from "../pages/cadastros/usuarios/lista-usuarios"
import CadastroFornecedores from "../pages/cadastros/fornecedores/cadastro-fornecedores"
import CadastroUsuarios from "../pages/cadastros/usuarios/cadastro-usuarios"
import CadastroProdutos from "../pages/cadastros/produtos/cadastro-produtos"
import CadastroExportadores from "../pages/cadastros/exportadores/cadastro-exportadores"
import CadastroClientes from "../pages/cadastros/clientes/cadastro-clientes"
import CadastroOrcamentos from "../pages/cadastros/orcamentos/cadastro-orcamentos"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  // Cadastros
  { path: "/lista-usuarios", component: ListaUsuarios },
  { path: "/lista-orcamentos", component: ListaOrcamentos },
  { path: "/lista-fornecedores", component: ListaFornecedores },
  { path: "/lista-clientes", component: ListaClientes },
  { path: "/lista-produtos", component: ListaProdutos },
  { path: "/lista-exportadores", component: ListaExportadores },
  { path: "/cadastro-usuarios", component: CadastroUsuarios },
  { path: "/cadastro-orcamentos", component: CadastroOrcamentos },
  { path: "/cadastro-fornecedores", component: CadastroFornecedores },
  { path: "/cadastro-clientes", component: CadastroClientes },
  { path: "/cadastro-produtos", component: CadastroProdutos },
  { path: "/cadastro-exportadores", component: CadastroExportadores },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [

  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
]

export { userRoutes, authRoutes }