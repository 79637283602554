import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, CardTitle,Progress,Label,Button, Table } from "reactstrap"
import "../../../assets/scss/datatables.scss"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Pagination from "../../../components/Common/Pagination"
import {getClientes,getCliente,deleteCliente} from "../../../helpers/backend_helper"
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
import SweetAlert from "react-bootstrap-sweetalert"
import { globalLoading } from "react-global-loading"
import {data} from "../../../common/ufs.json"
let PageSize = 5;

const ListaClientes = props => {
  const navigate = useHistory();
  const [rowItens, setRowItens] = useState([]);
  const [total, setTotal] = useState(0);
  const [consultaClientes, setConsultaClientes] = useState(true);
  const [currentPage, setCurrentPage] = useState(null);
  const [exclusao, setExclusao] = useState({});
  const [showMsg, setShowMessage] = useState(false)

  $(() => {
    $('#cnpj').mask('00.000.000/0000-00', {reverse: true});
  });

  const currentTableData = useMemo(async () => {
    consultarClientes()
  }, [currentPage]);

    useEffect(() => {
       if(consultaClientes) {
        setCurrentPage(1);
        consultarClientes();
        setConsultaClientes(false);
        }
    },[consultaClientes])

    function consultarClientes() {
      globalLoading.show();
      let nome = document.getElementsByName("nome")[0] && document.getElementsByName("nome")[0].value;
      let uf = document.getElementsByName("uf")[0] && document.getElementsByName("uf")[0].value;;
      let cnpj = document.getElementsByName("cnpj")[0] && document.getElementsByName("cnpj")[0].value;
      if(cnpj) {
        cnpj = cnpj.replace('.','').replace('.','').replace('-','').replace('/','');
      }
      getClientes(
        {"pagina":currentPage == null || currentPage === 0? 0: currentPage -1  ,
        "tamanho":PageSize, "nome":nome, "cnpj":cnpj, "uf":uf})
          .then((res) => {
            globalLoading.hide();
            let usuarios = [];
            for (var index = 0; index < res.data.length; index++) {
              let rowItem = {}
              rowItem["idCliente"] = res.data[index].idCliente
              rowItem["nome"] = res.data[index].nome
              rowItem["endereco"] = res.data[index].endereco
              rowItem["cnpj"] =  res.data[index].cnpj
              usuarios.push(rowItem);
            }
            setTotal(res.total);
            setRowItens(usuarios);
            setConsultaClientes(false);
          }).catch(err => {
            globalLoading.hide();
            if(err.response && err.response.status == 401) {
              localStorage.removeItem("token")
              localStorage.removeItem("authUser")
              navigate.push("/login")
            }
          })
    }

    const formatarCnpj =(cnpj) => {
      let cnpjFormatado = cnpj.replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
      return cnpjFormatado;
    }
  


    const toCadastroCliente = (cliente, isVizualizacao)=> {;
      navigate.push({
        pathname: 'cadastro-clientes',
        state: {cliente:cliente,vizualizacao:isVizualizacao} 
        });
    }

    const excluirCliente = ()=> {
    globalLoading.show();
      deleteCliente(exclusao.idCliente).then(res => {
      globalLoading.hide();
       toast.success('Cliente Excluido com sucesso!',{
         position: toast.POSITION.TOP_RIGHT
       })
       setConsultaClientes(true);
      }).catch(err=> {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          navigate.push("/login")
        }
       toast.error(err.response.data.message,{
         position: toast.POSITION.TOP_RIGHT
       })
      })
     }
     
     const excluirClienteHandle = (cliente)=> {
      setExclusao(cliente);
      setShowMessage(true);
     }

  return (


    <React.Fragment>
       <div className="page-content">
       <Breadcrumbs title="Tables" breadcrumbItem="Consulta de Clientes" />
        <Row>
          <Card>
            <CardBody>
            <Row>
            <Col lg={4}>
              <Label className="form-label">Razão Social</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Razão Social"
                      name="nome"
                    />
              </Col>
              <Col lg={4}>
              <Label className="form-label">Cnpj</Label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Cnpj"
                      name="cnpj"
                      id="cnpj"
                    />
              </Col>
              <Col lg={4}>
                  <Label className="form-label">UF</Label>
                  <select  className="form-control" name="uf" label="UF">
                      <option></option>
                      {data.map(uf => <option value={uf}>{uf}</option>)}
                  </select>
              </Col>
            </Row>
            <br></br>
            <Row className="">
              <Col lg={9}></Col>
              <Col lg={3} className="d-flex flex-row-reverse">
                <Link to="cadastro-clientes" className="btn btn-primary waves-effect waves-light">
                  Novo <i className="bx bx-plus font-size-8 align-middle me-1"></i>
                </Link>
                &nbsp; &nbsp; &nbsp;
                <Button
                      color="primary"
                      className="btn btn-primary waves-effect waves-light" onClick={() => setConsultaClientes(true)}>
                      Consultar <i className="bx bx-search font-size-8 align-middle me-1"></i>
                  </Button>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4">Lista de Clientes</CardTitle>
                    <table  className="table table-hover mb-0">
                      <thead className="table-light">
                        <tr>
                          <th style={{width:  '50%'}}>Razão Social</th>
                          <th style={{width:  '20%'}}>Endereço</th>
                          <th style={{width:  '18%'}}>Cnpj</th>
                          <th style={{width:  '12%', textAlign:'center'}}>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        { rowItens.map(row => 
                            <tr key={row.idCliente}>
                              <td style={{width: '50%'}}>{row.nome}</td>
                              <td style={{width: '20%'}}>{row.endereco}</td>
                              <td style={{width: '18%'}}>{formatarCnpj(row.cnpj)}</td>
                              <td style={{width: '12%', textAlign:'center'}}>
                                <button type="button" className="btn btn-light waves-effect waves-light" 
                                 onClick={() => toCadastroCliente(row,false) }>
                                  <i className="bx bx-edit font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => toCadastroCliente(row,true) }>
                                  <i className="bx bx-search font-size-8 align-middle me-1"></i>
                                </button>
                                &nbsp;
                                <button type="button" className="btn btn-light waves-effect waves-light"
                                onClick={() => excluirClienteHandle(row) }>
                                  <i className="bx bx-trash font-size-8 align-middle me-1"></i>
                                </button>
                              </td>
                              {showMsg ? (<SweetAlert
                                  title="Deseja Realmente Excluir este Cliente!"
                                  warning
                                  showCancel
                                  confirmBtnBsStyle="Sim"
                                  cancelBtnBsStyle="Não"
                                  onConfirm={() => {
                                    excluirCliente()
                                    setShowMessage(false);
                                  }}
                                  onCancel={() => {
                                    setShowMessage(false);
                                  }}> 
                                </SweetAlert>):null}
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                    <Row style={{display:'flex'}}>
                      <Col lg={5} ></Col>
                      <Col lg={1} >
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={total}
                            pageSize={PageSize}
                            onPageChange={page => setCurrentPage(page)}
                          />
                      </Col>
                    </Row>
                    
                  </CardBody>
                </Card>
              </Col>
            </Row>
              
            </CardBody>
          </Card>
        </Row>
       </div>
    </React.Fragment>
  )
}

ListaClientes.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default ListaClientes
