import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, Button, Form, FormFeedback, FormGroup, Input, Label, } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput,AvFeedback } from 'availity-reactstrap-validation';
import InputMask from "react-input-mask"
import Dropzone from "react-dropzone"
import { getClientesAll, getFornecedoresAll,createProduto,editProduto, getProduto, deleteAnexo } from "../../../helpers/backend_helper";
import { ToastContainer, toast } from 'react-toastify';
import { globalLoading } from "react-global-loading";
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

const CadastroProdutos = props => {
  const history = useHistory();

  const [foto1, setFoto1] = useState('')
  const [foto2, setFoto2] = useState('')
  const [cliente, setCliente] = useState({});
  const [fornecedor, setFornecedor] = useState({});
  const [fotoDesenhoTecnico, setFotoDesenhoTecnico] = useState('')
  const [salvarProduto, setSalvarProduto] = useState(false);
  const [produto, setProduto] = useState({});
  const [fornecedores, setFornecedores] = useState([]);
  const [consultaFornecedores, setConsultaFornecedores] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [consultaClientes, setConsultaClientes] = useState(true);
  const [idProduto, setIdProduto] = useState(null);
  const [consultarProduto, setConsultarProduto] = useState(false);
  const [vizualizacao, setVizualizacao] = useState(false);
  const [form, setForm] = useState({});

  useEffect(() => {
  	if(!(typeof foto1 == 'string')){
      if(foto1.type == 'image/jpeg' || foto1.type == 'image/png') {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(foto1)
        fileReader.onload = () => {
          setFoto1(fileReader.result);
        }
        fileReader.onerror = (error) => {
          console.log(error);
        }
      } else {
        toast.error('So pode ser enviado Imagens no formato PNG e JPEG',{
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  },[foto1]);

  useEffect(() => {
  	if(!(typeof foto2 == 'string')){
      if(foto2.type == 'image/jpeg' || foto2.type == 'image/png') {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(foto2)
        fileReader.onload = () => {
          setFoto2(fileReader.result);
        }
        fileReader.onerror = (error) => {
          console.log(error);
        }
      } else {
        toast.error('So pode ser enviado Imagens no formato PNG e JPEG',{
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  },[foto2]);

  useEffect(() => {
  	if(!(typeof fotoDesenhoTecnico == 'string')){
      if(fotoDesenhoTecnico.type == 'image/jpeg' || fotoDesenhoTecnico.type == 'image/png') {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(fotoDesenhoTecnico)
        fileReader.onload = () => {
          setFotoDesenhoTecnico(fileReader.result);
        }
        fileReader.onerror = (error) => {
          console.log(error);
        }
      } else {
        toast.error('So pode ser enviado Imagens no formato PNG e JPEG',{
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  },[fotoDesenhoTecnico]);

  useEffect(() => {
    if(consultaClientes) {
      getClientesAll()
      .then((res) => {
        setClientes(res.clientes);
        setConsultaClientes(false)
      }).catch(err => {
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
      });
    }
  },[consultaClientes])

  useEffect(() => {
    if(consultaFornecedores) {
      getFornecedoresAll()
      .then((res) => {
        setFornecedores(res.fornecedores);
        setConsultaFornecedores(false)
      }).catch(err => {
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
      });;
    }
  },[consultaFornecedores])

  useEffect(() => {
    if(typeof idProduto == 'number' && !produto.idProduto) {
      getProduto(idProduto)
        .then((res) => {
          setProduto(res.data);
          setCliente(res.data.cliente);
          setFornecedor(res.data.fornecedor);
    }).catch(err => {
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
      toast.error(err.message,{
        position: toast.POSITION.TOP_RIGHT
      });
    });;
  }
  },[idProduto])

  useEffect(() => {
    if(!idProduto && props.location && props.location.state && !produto.idProduto) {
      setIdProduto(props.location.state.produto.idProduto || {});
      setVizualizacao(props.location.state.vizualizacao);
    }
  })

  useEffect(() => {
    if(consultarProduto) {
      globalLoading.show();
      getProduto(idProduto)
        .then((res) => {
          globalLoading.hide();
          setProduto(res.data);
          setCliente(res.data.cliente);
          setFornecedor(res.data.fornecedor);
          setConsultarProduto(false);
    }).catch(err => {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
      toast.error(err.message,{
        position: toast.POSITION.TOP_RIGHT
      });
    });;
  }
  },[consultarProduto])

  const retirarCamposNumericosVazios = () => {
    if(produto.codBarras == '') {
      delete produto['codBarras']
    }
    if(produto.codBarrasCxColetiva == '') {
      delete produto['codBarrasCxColetiva']
    }
    if(produto.precoUnit == '') {
      delete produto['precoUnit']
    }
    if(produto.pesoBrutoUnit == '') {
      delete produto['pesoBrutoUnit']
    }
    if(produto.volumeCaixa == '') {
      delete produto['volumeCaixa']
    }
    if(produto.codOem == '') {
      delete produto['codOem']
    }
    if(produto.urlImg1 == '') {
      delete produto['urlImg1']
    }
    if(produto.urlImg2 == '') {
      delete produto['urlImg2']
    }
    if(produto.urlDesenhoTec == '') {
      delete produto['urlDesenhoTec']
    }
  }
 
  
  useEffect(() => {
    if(salvarProduto) {
      globalLoading.show();
      retirarCamposNumericosVazios();
      if(!produto.idProduto) {
        createProduto(produto).then(res => {
          globalLoading.hide();
            setFornecedor({});
            toast.success("Produto Salvo com Sucesso",{
              position: toast.POSITION.TOP_RIGHT
            }) 
            history.push('lista-produtos')
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
          toast.error(err.response.data.message,{
            position: toast.POSITION.TOP_RIGHT
          })
          produto.cliente = cliente;
          produto.fornecedor = fornecedor;
          setSalvarProduto(false);
        });
    } else {
      let produtoEdit = {...produto};
      produtoEdit.idCliente = typeof cliente === 'object' ? cliente.idCliente:cliente;
      produtoEdit.idFornecedor = typeof fornecedor === 'object' ? fornecedor.idFornecedor:fornecedor;
      delete produtoEdit['cliente'];
      delete produtoEdit['fornecedor'];
      editProduto(produtoEdit).then(res => {
        globalLoading.hide();
        setFornecedor({});
        toast.success("Produtos editado com Sucesso",{
          position: toast.POSITION.TOP_RIGHT
        }) 
        history.push('lista-produtos')
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
        setSalvarProduto(false);
      })
    }
    setSalvarProduto(false);
  }
  },[salvarProduto])

  const handleSubmit = (event, values) => {
    

    if(!produto.idProduto) {
      if(values.urlImg1) {
        values.urlImg1 = foto1;
      }
      if(values.urlImg2) {
        values.urlImg2 = foto2;
      }
      if(values.urlDesenhoTec) {
        values.urlDesenhoTec = fotoDesenhoTecnico;
      }
      setProduto(values)
    } else {
      produto.nome = values.nome;
      produto.descricao = values.descricao;
      produto.marca = values.marca; 
      produto.codBarras = values.codBarras;
      produto.codBarrasCxColetiva = values.codBarrasCxColetiva;
      produto.ncm = values.ncm;
      produto.qtdPCaixa = values.qtdPCaixa; 
      produto.precoUnit = values.precoUnit;
      produto.codOem = values.codOem; 
      produto.pesoBrutoUnit = values.pesoBrutoUnit; 
      produto.volumeCaixa = values.volumeCaixa;
      produto.observacoes = values.observacoes;
      if(values.urlImg1) {
        produto.urlImg1 = foto1;
        produto.img1 = null;
      }
      if(values.urlImg2) {
        produto.urlImg2 = foto2;
        produto.img2 = null;
      }
      if(values.urlDesenhoTec) {
        produto.urlDesenhoTec = fotoDesenhoTecnico;
        produto.imgDesenhoTec = null;
      }
    }

    setSalvarProduto(true);
  };

  const handleChangeCliente = (event) => {
    setCliente(event.target.value);
  };

  const handleChangeFornecedor = (event) => {
    setFornecedor(event.target.value);
  };

  const excluirImg1 = (url)=> {
    let produtoEx = {...url};
    produtoEx.img1 = '1';
    produtoEx.img2 = '';
    produtoEx.imgDesenhoTec = '';
    globalLoading.show();
    deleteAnexo(produtoEx).then(res => {
      globalLoading.hide();
     toast.success('Imagem Excluida com Sucesso!',{
       position: toast.POSITION.TOP_RIGHT
     });
     setConsultarProduto(true);
    }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
     toast.error(err.response.data.message,{
       position: toast.POSITION.TOP_RIGHT
     })
    })
   }

   const excluirImg2 = (url)=> {
    let produtoEx = {...url};
    produtoEx.img1 = '';
    produtoEx.img2 = '1';
    produtoEx.imgDesenhoTec = '';
    globalLoading.show();
    deleteAnexo(produtoEx).then(res => {
      globalLoading.hide();
     toast.success('Imagem Excluida com Sucesso!',{
       position: toast.POSITION.TOP_RIGHT
     });
     setConsultarProduto(true);
    }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
     toast.error(err.response.data.message,{
       position: toast.POSITION.TOP_RIGHT
     })
    })
   }

   const excluirImg3 = (url)=> {
    let produtoEx = {...url};
    produtoEx.img1 = '';
    produtoEx.img2 = '';
    produtoEx.imgDesenhoTec = '1';
    globalLoading.show();
    deleteAnexo(produtoEx).then(res => {
      globalLoading.hide();
      toast.success('Imagem Excluida com Sucesso!',{
        position: toast.POSITION.TOP_RIGHT
      });
     setConsultarProduto(true);
    }).catch(err=> {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
     toast.error(err.response.data.message,{
       position: toast.POSITION.TOP_RIGHT
     })
    })
   }

  return (
    <React.Fragment>
    <div className="page-content">
    
    <Breadcrumbs title="Tables" breadcrumbItem="Cadastro de Produtos" />
     <Row>
       <Card>
         <CardBody>
         <AvForm onValidSubmit={handleSubmit} ref={c => (setForm(c))}>
          <Row>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="nome">Nome</Label>
                <AvInput
                      name="nome"
                      required
                      type="text"
                      placeholder="Nome"
                      id="nome"
                      value= {produto.nome ? produto.nome : ''}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Nome</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <AvField type="select" name="idCliente" label="Cliente"
                value={produto.cliente? produto.cliente.idCliente:null}
                disabled ={vizualizacao} onChange={handleChangeCliente}
                validate={{required: { value: true, errorMessage: 'Selecione o Cliente'}}}>
                    <option></option>
                    {clientes.map(cliente => <option value={cliente.idCliente}>{cliente.nome}</option>)}
                </AvField>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <AvField type="select" name="idFornecedor" label="Fornecedor" 
                value={produto.fornecedor? produto.fornecedor.idFornecedor:null}
                disabled ={vizualizacao} onChange={handleChangeFornecedor}
                validate={{required: { value: true, errorMessage: 'Selecione o Fornecedor'}}}>
                    <option></option>
                    {fornecedores.map(fornecedor => <option value={fornecedor.idFornecedor}>{fornecedor.nome}</option>)}
                </AvField>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="nome">Código Interno do Cliente</Label>
                <AvInput
                      name="codInternoCliente"
                      required
                      type="text"
                      placeholder="Código Interno do Cliente"
                      id="codInternoCliente"
                      value= {produto.codInternoCliente ? produto.codInternoCliente : ''}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Código Interno do Cliente</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={12}>
              <AvGroup>
                <Label className="form-label" htmlFor="descricao">Descrição</Label>
                <AvInput
                      name="descricao"
                      required
                      type="textarea"
                      rows="5"
                      placeholder="Descrição"
                      id="descricao"
                      value= {produto.descricao ? produto.descricao : ''}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe a Descrição</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="marca">Marca</Label>
                <AvInput
                      name="marca"
                      required
                      type="text"
                      placeholder="Marca"
                      id="marca"
                      value= {produto.marca ? produto.marca : ''}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe a Marca</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="codBarras">Código de Barras</Label>
                <AvInput
                      name="codBarras"
                      type="number"
                      placeholder="Código de Barras"
                      id="codBarras"
                      value= {produto.codBarras ? produto.codBarras : ''}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe a Marca</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="codBarrasCxColetiva">Código de Barras Caixa Coletiva</Label>
                <AvInput
                      name="codBarrasCxColetiva"
                      type="number"
                      placeholder="Código de Barras Caixa Coletiva"
                      id="codBarrasCxColetiva"
                      value= {produto.codBarrasCxColetiva ? produto.codBarrasCxColetiva : ''}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="ncm">NCM</Label>
                <AvInput
                      name="ncm"
                      type="text"
                      placeholder="NCM"
                      id="ncm"
                      value= {produto.ncm ? produto.ncm : ''}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="qtdCaixa">Quantidade Produtos Caixa</Label>
                <AvInput
                      name="qtdPCaixa"
                      required
                      type="number"
                      placeholder="Quantidade Produtos Caixa"
                      id="qtdPCaixa"
                      value= {produto.qtdPCaixa ? produto.qtdPCaixa : null}
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe a Quantidade de Produtos Em Caixa</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="precoUnit">Preço Unitário</Label>
                <AvInput
                      name="precoUnit"
                      type="number"
                      placeholder="Preço Unitário"
                      id="precoUnit"
                      value= {produto.precoUnit ? produto.precoUnit : null}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="codOem">Código OEM</Label>
                <AvInput
                      name="codOem"
                      type="text"
                      placeholder="Código OEM"
                      id="codOem"
                      value= {produto.codOem ? produto.codOem : null}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
            <Col md={3}>
              <AvGroup>
                <Label className="form-label" htmlFor="pesoBrutoUnit">Peso Bruto Unitário</Label>
                <AvInput
                      name="pesoBrutoUnit"
                      type="number"
                      placeholder="Peso Bruto Unitário"
                      id="pesoBrutoUnit"
                      value= {produto.pesoBrutoUnit ? produto.pesoBrutoUnit : null}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={6}>
              <AvGroup>
                <Label className="form-label" htmlFor="volumeCaixa">Volume da Caixa</Label>
                <AvInput
                      name="volumeCaixa"
                      type="number"
                      placeholder="Volume da Caixa"
                      id="volumeCaixa"
                      value= {produto.volumeCaixa ? produto.volumeCaixa : null}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
            <Col md={6}>
              <AvGroup>
                <Label className="form-label" htmlFor="observacoes">Observações</Label>
                <AvInput
                      name="observacoes"
                      type="text"
                      placeholder="Observações"
                      id="observacoes"
                      value= {produto.observacoes ? produto.observacoes : ''}
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
            {!vizualizacao && (<Label className="form-label" htmlFor="urlImg1">Selecione a primeira foto</Label>)}
              {!vizualizacao && (<AvInput type="file"  accept="image/png, image/jpeg"   name="urlImg1" id="urlImg1" onChange={(e) => setFoto1(e.target.files[0])} className="form-control"/>)}
              <br></br>
              {(produto.img1 && !vizualizacao) && (<Button color="primary" 
                    onClick={(e) => {
                      excluirImg1(produto)
                    }}
                        className="btn btn-primary waves-effect waves-light">
                      <i className="bx bx-x font-size-8 align-middle me-1"></i>
                  </Button>)}
            </Col>
            <Col md={4}>
            {!vizualizacao && (<Label className="form-label" htmlFor="urlImg2">Selecione a segunda foto</Label>)}
              {!vizualizacao && (<AvInput type="file" accept="image/png, image/jpeg"   name="urlImg2" id="urlImg2" onChange={(e) => setFoto2(e.target.files[0])} className="form-control"/>)}
              <br></br>
              {(produto.img2 && !vizualizacao) && (<Button color="primary" 
              onClick={(e) => {
                      excluirImg2(produto)
                    }}
                        className="btn btn-primary waves-effect waves-light">
                      <i className="bx bx-x font-size-8 align-middle me-1"></i>
                  </Button>)}
            </Col>
            <Col md={4}>
            {!vizualizacao && (<Label className="form-label" htmlFor="urlDesenhoTec">Selecione a foto do desenho tecnico</Label>)}
              {!vizualizacao && (<AvInput type="file" accept="image/png, image/jpeg"   name="urlDesenhoTec" id="urlDesenhoTec" 
              onChange={(e) => setFotoDesenhoTecnico(e.target.files[0])} className="form-control"/>)}
                <br></br>
                {(produto.imgDesenhoTec && !vizualizacao) && (<Button color="primary"
                        onClick={(e) => {
                              excluirImg3(produto)
                            }}
                        className="btn btn-primary waves-effect waves-light">
                      <i className="bx bx-x font-size-8 align-middle me-1"></i>
                  </Button>)}
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
            {produto.img1 && (<img src={produto.img1 ? produto.img1 : ''} alt="imgFoto1" width={100} height={100}></img>)}
            </Col>
           <Col md={4}>
           {produto.img2 && (<img src={produto.img2 ? produto.img2 : ''} alt="imgFoto1" width={100} height={100}></img>)}
            </Col>
            <Col md={4}>
            {produto.imgDesenhoTec && (<img src={produto.imgDesenhoTec ? produto.imgDesenhoTec : ''} alt="imgFoto1" width={100} height={100}></img>)}
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col lg={9}></Col>
                <Col lg={3} className="d-flex flex-row-reverse">
                {!vizualizacao && (<Button
                        color="primary" type="submit"
                        className="btn btn-primary waves-effect waves-light">
                        salvar Produto <i className="bx bx-check font-size-8 align-middle me-1"></i>
                  </Button>
                  )}
                  &nbsp;
                  <Link to="lista-produtos" className="btn btn-primary waves-effect waves-light">
                    voltar <i className="bx bx-arrow-back font-size-8 align-middle me-1"></i>
                  </Link>
                </Col>
            </Row>
          </AvForm>
         </CardBody>
       </Card>
     </Row>
    </div>
 </React.Fragment>
  )
}


export default CadastroProdutos
