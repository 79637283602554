import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, Button, Form, FormFeedback, FormGroup, Input, Label, } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput,AvFeedback } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getUsuario,editUsuario,createUsuario} from "../../../helpers/backend_helper"
import { globalLoading } from "react-global-loading";

const CadastroUsuarios = props => {
  const history = useHistory();
  const [salvarUsuario, setSalvarUsuario] = useState(false);
  const [usuario, setUsuario] = useState({});
  const [vizualizacao, setVizualizacao] = useState(false);
  const [idUsuario, setIdUsuario] = useState(null);
  
  useEffect(() => {
    if(salvarUsuario) {
      globalLoading.show();
      if(!usuario.idUsuario) {
        createUsuario(usuario).then(res => {
          globalLoading.hide();
            setUsuario({});
            toast.success("usuario Salvo com Sucesso",{
              position: toast.POSITION.TOP_RIGHT
            }) 
            history.push('lista-usuarios')
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
          toast.error(err.response.data.message,{
            position: toast.POSITION.TOP_RIGHT
          }) 
          setSalvarUsuario(false);
        });
    } else {
      editUsuario(usuario).then(res => {
        globalLoading.hide();
        setUsuario({});
        toast.success("usuario editado com Sucesso",{
          position: toast.POSITION.TOP_RIGHT
        }) 
        history.push('lista-usuarios')
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.response.data.message,{
          position: toast.POSITION.TOP_RIGHT
        });
        setSalvarUsuario(false);
      })
    setSalvarUsuario(false);
   }
  }
 },[salvarUsuario])

  useEffect(() => {
    if(usuario && typeof idUsuario == 'number' && !usuario.idUsuario) {
      globalLoading.show();
      getUsuario(idUsuario)
    .then((res) => {
      globalLoading.hide();
          setUsuario(res);
    }).catch(err => {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
      }
    });
  }
  },[idUsuario])

  useEffect(() => {
    if(!idUsuario && props.location && props.location.state && !usuario.idUsuario) {
      setIdUsuario(props.location.state.usuario.idUsuario || {});
      setVizualizacao(props.location.state.vizualizacao);
    }
  })

  const handleSubmit = (event, values) => {
    if(!usuario.idUsuario) {
      setUsuario(values)
    } else {
      usuario.email = values.email;
      usuario.senha = values.senha;
    }
    setSalvarUsuario(true);
  };

  return (
    <React.Fragment>
    <div className="page-content">
    <ToastContainer />
    <Breadcrumbs title="Tables" breadcrumbItem="Cadastro de Usuarios" />
     <Row>
       <Card>
         <CardBody>
         <AvForm onValidSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <AvGroup>
                <Label className="form-label" htmlFor="email">Email</Label>
                <AvInput 
                      name="email"
                      required
                      type="email"
                      value= {usuario.email ? usuario.email : ''}
                      placeholder="Email"
                      id="nome"
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe o Email</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={6}>
            <AvGroup>
            <Label className="form-label" htmlFor="senha">Senha</Label>
                <AvInput
                      name="senha" 
                      required
                      type="password"
                      placeholder="Senha"
                      id="senha"
                    />
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col lg={9}></Col>
                <Col lg={3} className="d-flex flex-row-reverse">
                <Button
                        color="primary" type="submit"
                        className="btn btn-primary waves-effect waves-light">
                        salvar usuario <i className="bx bx-check font-size-8 align-middle me-1"></i>
                  </Button>
                  &nbsp;
                  <Link to="lista-Usuarios" className="btn btn-primary waves-effect waves-light">
                    voltar <i className="bx bx-arrow-back font-size-8 align-middle me-1"></i>
                  </Link>
                </Col>
            </Row>
          </AvForm>
         </CardBody>
       </Card>
     </Row>
    </div>
 </React.Fragment>
  )
}

CadastroUsuarios.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default CadastroUsuarios
