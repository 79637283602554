import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { CREATE_USUARIOS, DELETE_USUARIOS, EDIT_USUARIOS, GET_USUARIO, GET_USUARIOS } from "./actionTypes"
import { getUsuariosSucess, getUsuariosErro, createUsuarioSuccess, createUsuarioErro, deleteUsuarioSuccess, deleteUsuarioErro, editUsuarioSuccess, editUsuarioErro, getUsuarioSucess, getUsuarioErro } from "./actions"

//Include Both Helper File with needed methods
import { getUsuarios,getUsuario,createUsuario,editUsuario,deleteUsuario } from "../../helpers/backend_helper"

function* listar(evt) {
  try {
    const response = yield call(getUsuarios,evt.paginacao)
    yield put(getUsuariosSucess(response.data))
  } catch (error) {
    yield put(getUsuariosErro(error))
  }
}

function* criar(evt) {
  try {
    const response = yield call(createUsuario, evt.usuario)
    yield put(createUsuarioSuccess(response))
  } catch (error) {
    yield put(createUsuarioErro(error))
  }
}

function* deletar({ idUsuario }) {
  try {
    const response = yield call(deleteUsuario, idUsuario)
    yield put(deleteUsuarioSuccess(response))
  } catch (error) {
    yield put(deleteUsuarioErro(error))
  }
}

function* atualizar(evt) {
  try {
    const response = yield call(editUsuario, evt.usuario)
    yield put(editUsuarioSuccess(response))
  } catch (error) {
    yield put(editUsuarioErro(error))
  }
}

function* consultar({ idUsuario }) {
  try {
    const response = yield call(getUsuario, idUsuario)
    yield put(getUsuarioSucess(response))
  } catch (error) {
    yield put(getUsuarioErro(error))
  }
}

function* usuariosSaga() {
  yield takeEvery(GET_USUARIOS, listar)
  yield takeEvery(CREATE_USUARIOS, criar)
  yield takeEvery(EDIT_USUARIOS, atualizar)
  yield takeEvery(DELETE_USUARIOS, deletar)
  yield takeEvery(GET_USUARIO, consultar)
}


export default usuariosSaga
