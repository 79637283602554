import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Row, Col, CardBody, Card, Button, Form, FormFeedback, FormGroup, Input, Label, } from "reactstrap"
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory } from "react-router-dom";
import { AvForm, AvField, AvGroup, AvInput,AvFeedback } from 'availity-reactstrap-validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getCliente,editCliente,createCliente} from "../../../helpers/backend_helper"
import {data} from "../../../common/ufs.json"
import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 
import { globalLoading } from "react-global-loading";


const CadastroClientes = props => {
  const history = useHistory();
  const [salvarCliente, setSalvarCliente] = useState(false);
  const [Cliente, setCliente] = useState({});
  const [uf, setUF] = useState('');
  const [vizualizacao, setVizualizacao] = useState(false);
  const [idCliente, setIdCliente] = useState(null);
  
  $(() => {
    $('#cnpj').mask('00.000.000/0000-00', {reverse: true});
    $('#cep').mask('00000-000', {reverse: true});
  });

  useEffect(() => {
    if(salvarCliente) {
      globalLoading.show();
      Cliente.cep = Cliente.cep.replace('.','').replace('-','');
      Cliente.cnpj = Cliente.cnpj.replace('.','').replace('-','').replace('/','');
      if(!Cliente.idCliente) {
        createCliente(Cliente).then(res => {
          globalLoading.hide();
            setCliente({});
            toast.success("Cliente Salvo com Sucesso",{
              position: toast.POSITION.TOP_RIGHT
            }) 
            history.push('lista-clientes')
        }).catch(err => {
          globalLoading.hide();
          if(err.response && err.response.status == 401) {
            localStorage.removeItem("token")
            localStorage.removeItem("authUser")
            history.push("/login")
          }
          toast.error(err.message,{
            position: toast.POSITION.TOP_RIGHT
          }) 
        });
    } else {
      editCliente(Cliente).then(res => {
        globalLoading.hide();
        setCliente({});
        toast.success("Cliente editado com Sucesso",{
          position: toast.POSITION.TOP_RIGHT
        }) 
        history.push('lista-clientes')
      }).catch(err => {
        globalLoading.hide();
        if(err.response && err.response.status == 401) {
          localStorage.removeItem("token")
          localStorage.removeItem("authUser")
          history.push("/login")
        }
        toast.error(err.message,{
          position: toast.POSITION.TOP_RIGHT
        });
      })
    setSalvarCliente(false);
   }
  }
 },[salvarCliente])

  useEffect(() => {
    if(Cliente && typeof idCliente == 'number' && !Cliente.idCliente) {
    globalLoading.show();
    getCliente(idCliente)
    .then((res) => {
          globalLoading.hide();
          res.data.cnpj = formatarCnpj(res.data.cnpj);
          res.data.cep = formatarCep(res.data.cep);
          setCliente(res.data);
    }).catch(err => {
      globalLoading.hide();
      if(err.response && err.response.status == 401) {
        localStorage.removeItem("token")
        localStorage.removeItem("authUser")
        history.push("/login")
      }
    });
  }
  },[idCliente]);

  const formatarCnpj =(cnpj) => {
    let cnpjFormatado = cnpj.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');
    return cnpjFormatado;
  }

  const formatarCep =(cep) => {
    let cepFormatado = cep.replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1')
    return cepFormatado;
  }

  useEffect(() => {
    if(!idCliente && props.location && props.location.state && !Cliente.idCliente) {
      setIdCliente(props.location.state.cliente.idCliente || {});
      setVizualizacao(props.location.state.vizualizacao);
    }
  })

  const handleSubmit = (event, values) => {
    if(!Cliente.idCliente) {
      setCliente(values)
    } else {
      Cliente.nome = values.nome;
      Cliente.cnpj = values.cnpj;
      Cliente.descricao = values.descricao;
      Cliente.marca = values.marca;
      Cliente.endereco = values.endereco;
      Cliente.cep = values.cep;
      Cliente.uf = values.uf;
      Cliente.portoChegada = values.portoChegada;
    }
    setSalvarCliente(true);
  };

  const handleChangeUF = (event) => {
    setUF(event.target.value);
  };

  return (
    <React.Fragment>
    <div className="page-content">
    <ToastContainer />
    <Breadcrumbs title="Tables" breadcrumbItem="Cadastro de Clientes" />
     <Row>
       <Card>
         <CardBody>
         <AvForm onValidSubmit={handleSubmit}>
          <Row>
            <Col md={4}>
              <AvGroup>
                <Label className="form-label" htmlFor="nome">Razão Social</Label>
                <AvInput 
                      name="nome"
                      required
                      type="text"
                      value= {Cliente.nome ? Cliente.nome : ''}
                      placeholder="Razão Social"
                      id="nome"
                      disabled ={vizualizacao}
                    />
               <AvFeedback>Informe a Razão Social</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={4}>
              <AvGroup>
                  <Label className="form-label" htmlFor="cnpj">CNPJ</Label>
                  <AvInput 
                      name="cnpj"
                      required
                      type="text"
                      value= {Cliente.cnpj ? Cliente.cnpj : ''}
                      placeholder="CNPJ"
                      id="cnpj"
                      disabled ={vizualizacao}/>
                  
                <AvFeedback>Informe o CNPJ</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={4}>
              <AvGroup>
                  <Label className="form-label" htmlFor="marca">Marca</Label>
                  <AvInput 
                      name="marca"
                      required
                      type="text"
                      value= {Cliente.marca ? Cliente.marca : ''}
                      placeholder="Marca"
                      id="marca"
                      disabled ={vizualizacao}/>
                  
                <AvFeedback>Informe a Marca</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
              <AvGroup>
                  <Label className="form-label" htmlFor="cep">CEP</Label>
                  <AvInput 
                      name="cep"
                      required
                      type="text"
                      value= {Cliente.cep ? Cliente.cep : ''}
                      placeholder="CEP"
                      id="cep"
                      disabled ={vizualizacao}/>
                  
                <AvFeedback>Informe o CEP</AvFeedback>
              </AvGroup>
            </Col>
            <Col md={8}>
              <AvGroup>
                  <Label className="form-label" htmlFor="endereco">Endereco</Label>
                  <AvInput 
                      name="endereco"
                      required
                      type="text"
                      value= {Cliente.endereco ? Cliente.endereco : ''}
                      placeholder="Endereco"
                      id="endereco"
                      disabled ={vizualizacao}/>
                  
                <AvFeedback>Informe o Endereço</AvFeedback>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md={4}>
            <AvGroup>
                <AvField type="select" name="uf" label="UF"
                  value={Cliente.uf? Cliente.uf:null}
                  disabled ={vizualizacao} onChange={handleChangeUF}
                  validate={{required: { value: true, errorMessage: 'Selecione a UF'}}}>
                      <option></option>
                      {data.map(uf => <option value={uf}>{uf}</option>)}
                </AvField>
              </AvGroup>
            </Col>
            <Col md={8}>
              <AvGroup>
                  <Label className="form-label" htmlFor="portoChegada">Porto de Chegada</Label>
                  <AvInput 
                      name="portoChegada"
                      type="text"
                      value= {Cliente.portoChegada ? Cliente.portoChegada : ''}
                      placeholder="Porto de Chegada"
                      id="portoChegada"
                      disabled ={vizualizacao}/>
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col md={12}>
            <AvGroup>
                <Label className="form-label" htmlFor="descricao">Descrição</Label>
                <AvInput
                      name="descricao"
                      type="textarea"
                      rows="5"
                      value={Cliente.descricao ? Cliente.descricao : ''}
                      placeholder="Descrição"
                      id="descricao"
                      disabled ={vizualizacao}
                    />
              </AvGroup>
            </Col>
          </Row>
          <br></br>
          <Row>
          <Col lg={9}></Col>
                <Col lg={3} className="d-flex flex-row-reverse">
                {!vizualizacao && (<Button
                        color="primary" type="submit"
                        className="btn btn-primary waves-effect waves-light">
                        salvar Cliente <i className="bx bx-check font-size-8 align-middle me-1"></i>
                  </Button>
                  )}
                  &nbsp;
                  <Link to="lista-Clientes" className="btn btn-primary waves-effect waves-light">
                    voltar <i className="bx bx-arrow-back font-size-8 align-middle me-1"></i>
                  </Link>
                </Col>
            </Row>
          </AvForm>
         </CardBody>
       </Card>
     </Row>
    </div>
 </React.Fragment>
  )
}

CadastroClientes.propTypes = {
  tasks: PropTypes.array,
  onGetTasks: PropTypes.func,
}


export default CadastroClientes
