import React from "react"
import { Row} from "reactstrap"



const Dashboard = () => {

  return (
    <React.Fragment>
      <div className="page-content">
  
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
           

            </div>
          </div>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default Dashboard