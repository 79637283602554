/* USUARIOS */
export const GET_USUARIOS = "GET_USUARIOS"
export const GET_USUARIOS_SUCCESS = "GET_USUARIOS_SUCCESS"
export const GET_USUARIOS_FAIL = "GET_USUARIOS_FAIL"
export const GET_USUARIO = "GET_USUARIO"
export const GET_USUARIO_SUCCESS = "GET_USUARIO_SUCCESS"
export const GET_USUARIO_FAIL = "GET_USUARIO_FAIL"
export const CREATE_USUARIOS = "CREATE_USUARIOS"
export const CREATE_USUARIOS_SUCCESS = "CREATE_USUARIOS_SUCCESS"
export const CREATE_USUARIOS_FAIL = "CREATE_USUARIOS_FAIL"
export const EDIT_USUARIOS = "EDIT_USUARIOS"
export const EDIT_USUARIOS_SUCCESS = "EDIT_USUARIOS_SUCCESS"
export const EDIT_USUARIOS_FAIL = "EDIT_USUARIOS_FAIL"
export const DELETE_USUARIOS = "DELETE_USUARIOS"
export const DELETE_USUARIOS_SUCCESS = "DELETE_USUARIOS_SUCCESS"
export const DELETE_USUARIOS_FAIL = "DELETE_USUARIOS_FAIL"
